import React, { useEffect, useState } from "react";
import "./ShopByPattern.css";
import { Col, Button } from "react-bootstrap";
import { collection, getDocs } from "firebase/firestore";
import { db, fireStorage } from "../../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { createSearchParams, useNavigate } from "react-router-dom";

function ShopByPattern() {
  const navigate = useNavigate();

  const [products, setProducts] = useState<any>([]);
  const [collectionBanner, setCollectionBanner] = useState<any>("");
  const [patterns, setPatterns] = useState<any>([]);
 
  useEffect(() => {
    getProducts();
    getShopByPattern();
    getNewCollectionBanner();
  }, []);

  const getShopByPattern = async () => {
    await getDocs(collection(db, "shopByPattern")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setPatterns(newData);
    });
  };

  const getNewCollectionBanner = async () => {
    const imgRef = ref(fireStorage, `collectionBannerImage`);
    const res = await getDownloadURL(imgRef);
    setCollectionBanner(res);
  };

  const getProducts = async () => {
    await getDocs(collection(db, "products")).then((querySnapshot) => {
      const newData: any = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProducts(newData);
    });
  };

  return (
    <>
      <div className="spacingContainer mt-5 pt-5">
        <div className="ShopByPatternContainer w-100">
          <h4 className="col-12">Shop By Pattrens</h4>


          <div className="CategoriesList mt-3">
            {patterns.map((val: any, ind: any) => (
              <div
                key={ind}
                className={`CategoryDiv pt-1 ${
                  ind === 0 ? "ml-0 mr-3" : "mx-3"
                }`}
                onClick={() =>
                  navigate({
                    pathname: "/products",
                    search: createSearchParams({
                      pattern: val?.name,
                    }).toString(),
                  })
                }
              >
                <div className="CategoryImg ShopByPatternListIMG">
                  <img src={val?.image} />
                </div>
                <p className="w-100 text-center mt-3 categoryName">
                  {val?.name}
                </p>
              </div>
            ))}
          </div>
         
          {/* <div className="ShopByPatternList d-flex w-100 mt-3">
            {patterns.slice(0, 3).map((val: any, ind: any) => (
              <div
                className="ShopByPatternItems col-md-3 col-10 px-2"
                onClick={() =>
                  navigate({
                    pathname: "/products",
                    search: createSearchParams({
                      pattern: val?.name,
                    }).toString(),
                  })
                }
              >
                <div className="ShopByPatternDivImg w-100">
                  <img src={val?.image} />
                </div>
                <div className="linkDiv w-100 py-3">
                  <p>
                    <Link to="./">{val?.name}</Link>
                  </p>
                </div>
              </div>
            ))}
          </div> */}

          <Col className="displayManul">
            <Col xs={12} md={6} className="d-flex flex-wrap mobileScroll">
              {products?.map((val: any, ind: number) => (
                <>
                  {val?.shopPattern == "true" && (
                    <Col
                      key={ind}
                      className="px-2 mt-4"
                      xs={10}
                      md={6}
                      onClick={() =>
                        navigate({
                          pathname: "/product",
                          search: createSearchParams({
                            pi: val?.id,
                          }).toString(),
                        })
                      }
                    >
                      <div className="FreshProductDiv w-100">
                        <div className="FreshProductDivImg FreshProductDivImgPattern w-100">
                          <img src={val?.image[0]} />
                        </div>
                        <p className="w-100 text-center mb-2 mt-3 patternProductName">
                          {val?.name}
                        </p>

                        <a
                          href="https://api.whatsapp.com/send?phone=918104380250"
                          target="_blank"
                        >
                          <Button variant="success" className="w-100">
                            Contact Us <FaWhatsapp />
                          </Button>
                        </a>

                        <div className="d-flex w-100 align-items-center justify-content-center mt-3">
                          {val?.sizes.map((value: any, index: number) => (
                            <span key={ind} className="mx-1 freshSizeSpn px-1">
                              {value?.label}
                            </span>
                          ))}
                        </div>
                      </div>
                    </Col>
                  )}
                </>
              ))}
            </Col>
            <Col xs={12} md={6}>
              <Col className="px-2 mt-4">
                <div className="bigImage">
                  <div className="absoluteDiv w-100 h-100 position-absolute">
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                      <div>
                         <div className="w-100 d-flex align-items-center justify-content-center">
                          <Button
                            className="px-4 nwColBtnMt"
                            variant="light"
                            onClick={() => navigate("/products")}
                          >
                            View All
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={collectionBanner} />
                </div>
              </Col>
            </Col>
          </Col>
        </div>
      </div>
    </>
  );
}

export default ShopByPattern;

import React from "react";

function AboutUs() {
  return (
    <>
      <div className="spacingContainer aboutUsPageDiv py-4">
        <h3 className="mt-4">ABOUT US </h3>
        <p className="mb-4 mt-2">
          Welcome to ProKills. Our primary mission is to redefine menswear by
          combining classic craftsmanship with modern designs, creating pieces
          that appeal to the refined man.
        </p>

        <h3 className="mt-4">OUR STORY</h3>
        <p className="mb-4 mt-2">
          Founded in the Year 2017, ProKills emerged from a passion for superior
          quality and a desire to bring sophistication back to men's fashion. We
          believe that every man deserves to look and feel his best, whether
          he's at a business meeting, a casual gathering, or a formal event. As
          of now, we are a Super Stockist & Distributor for approximately 5+
          Cities.
        </p>

        <h3 className="mt-4">OUR PHILOSOPHY</h3>
        <p className="mb-4 mt-2">
          At ProKills, we are driven by the principles of excellence, integrity,
          and innovation. Each garment we produce is a testament to our
          commitment to these values, ensuring that our customers receive only
          the finest in menswear. We source premium materials from around the
          country, and our skilled artisans bring these elements together with
          meticulous attention to detail.
        </p>

        <h3 className="mt-4">OUR COLLECTION</h3>
        <p className="mb-4 mt-2">
          Our collection features a versatile range of menswear essentials, from
          boxers, Bermuda to Jeans & Casual Pants. Every piece is designed to
          offer both comfort and style, allowing you to effortlessly transition
          from day to night. With a focus on fit, fabric, and function, our
          clothing is made to complement the modern man's lifestyle.
        </p>

        <h3 className="mt-4">OUR PROMISE</h3>

        <p className="mb-4 mt-2">
          At ProKills, customer satisfaction is of utmost priority. We are
          dedicated to providing an exceptional shopping experience, from
          personalized styling advice to seamless customer service. We invite
          you to explore our collection and discover the perfect pieces that
          will elevate your wardrobe. Thank you for choosing Us. Together, we
          make fashion not just a statement, but a lifestyle!!
        </p>
      </div>
    </>
  );
}

export default AboutUs;

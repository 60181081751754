import React from "react";
import { Col } from "react-bootstrap";
import logo from "../../Assets/logo.png";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="footerContainer">
        <Col className="displayManul align-items-center justify-content-between">
          <Col md={6} xs={12}>
            <ul>
              <li className="py-4">
                <img src={logo} />
              </li>
              <li className="py-2">
                <p>
                  Prokills brands is among the leading apparel manufacturers in
                  India. Producing around 4,000 garments per day.
                </p>
              </li>
              <li className="py-2">
                <h6>Brand owned by , VR Enterprises Private Limited</h6>
              </li>
              <li className="py-2">
                <h6>Contact us : For Queries & Complaints</h6>
                <p>Whatsapp: +91 8104380250 (Available 9:30AM - 6:30PM)</p>
                <p>Mail: vrenterprises0301@gmail.com</p>
              </li>
              <li className="py-2">
                <h6>Operational Address :</h6>
                <p>
                AB - 167, Vivekanand Marg, Nirman Nagar, Jaipur - 302019.
                </p>
              </li>
            </ul>
          </Col>
          <Col className="d-flex justifyManul" md={6} xs={12}>
            <ul>
              <li className="py-2">
                <Link to="/">Home</Link>
              </li>
              <li className="py-2">
                <Link to="/AboutUs">About Us</Link>
              </li>
              <li className="py-2">
                <Link to="/products">Products</Link>
              </li>
              <li className="py-2">
                <Link to="/VisitUs">Visit Us</Link>
              </li>
            </ul>
          </Col>
        </Col>

        <div className="footerBottom py-4 mt-4">
          <p>Copyright © Prokills 2024</p>
        </div>
      </div>
    </>
  );
}

export default Footer;

import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Col } from "react-bootstrap";
import logo from "../../Assets/logo.png";
import "./Header.css";
import { Link, NavLink, createSearchParams } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  const [categories, setCategories] = useState<any>([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    await getDocs(collection(db, "categories")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log(newData);
      setCategories(newData);
    });
  };

  return (
    <div className="headerMenu">

      <div className="contactPanel py-3">
      <h6 className="mb-0">
      To Place an Order, You Can Whatsapp Us At - <a href="https://api.whatsapp.com/send?phone=918104380250" target='_blank'>+91 8104380250</a>
      </h6>
      </div>
      <Navbar expand="lg" bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} width={120} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <NavLink
                to="/"
                className={`mx-md-4 NavLinkCus ({ isActive, isPending }) =>
              isActive ? "active" : ""`}
              >
                Home
              </NavLink>

              <NavLink
                to="AboutUs"
                className={`mx-md-4 NavLinkCus ({ isActive, isPending }) =>
              isActive ? "active" : ""`}
              >
                About Us
              </NavLink>

              <NavLink
                to="products"
                className={`mx-md-4 NavLinkCus ({ isActive, isPending }) =>
              isActive ? "active" : ""`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                Products
              </NavLink>
              <NavLink
                to="contact-us"
                className={`mx-md-4 NavLinkCus ({ isActive, isPending }) =>
              isActive ? "active" : ""`}
              >
                Contact Us
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      { isDropdownVisible &&
        <div
          className="productsMenuDropDown"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >

          <div className="carrot"></div>
          <Col>
            <div className="d-flex headingDiv">
              {categories?.map((val: any, ind: number) => (
                <Col>
                  <h4 
                  onClick={() =>
                    navigate({
                      pathname: "/products",
                      search: createSearchParams({
                        category: val?.id,
                      }).toString(),
                    })
                  }
                  >{val?.name}</h4>
                </Col>
              ))}
            </div>

            <div className="d-flex patternsDiv pt-2">
              {categories?.map((val: any, ind: number) => (
                <Col>
                  {val?.pattern?.map((value: any, index: number) => (
                    <p className="py-1" 
                    onClick={() =>
                      navigate({
                        pathname: "/products",
                        search: createSearchParams({
                          category: val?.id,
                          pattern: value?.label,
                        }).toString(),
                      })
                    }
                    >{value?.label}</p>
                  ))}
                </Col>
              ))}
            </div>
          </Col>
        </div>
      }
    </div>
  );
}

export default Header;

import React from "react";
import PublicRouter from "../Routes/PublicRouter";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { LiaWhatsapp } from "react-icons/lia";

function Layout() {
  return (
    <>
      <Header />
      <PublicRouter />
      <Footer />

      <a href="https://api.whatsapp.com/send?phone=918104380250" target='_blank'>
      <div className="whatsappFab">
        <LiaWhatsapp />
      </div>
      </a>

    </>
  );
}

export default Layout;

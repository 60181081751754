import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/Home";
import AboutUs from "../Pages/AboutUs/AboutUs";
import Products from "../Pages/Products/Products";
import ProductDetails from "../Pages/Products/ProductDetails";
import VisitUs from "../Pages/VisitUs/VisitUs";

function PublicRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="AboutUs" element={<AboutUs />} />
      <Route path="products" element={<Products />} />
      <Route path="product" element={<ProductDetails />} />
      <Route path="contact-us" element={<VisitUs />} />

    </Routes>
  );
}

export default PublicRouter;

import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form } from "react-bootstrap";
import "./Products.css";
import "../../App.css";
import banner2 from "../../Assets/banner2.webp";
import { FaWhatsapp } from "react-icons/fa";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { createSearchParams, useNavigate } from "react-router-dom";

function Products() {
  let url = window.location.href;
  const lastUrl: any = url?.split("?")[1];

  const navigate = useNavigate();
  const [products, setProducts] = useState<any>([]);
  const [patternFilters, setPatternFilters] = useState<any>([]);
  const [colorFilters, setColorFilters] = useState<any>([]);
  const [sizeFilters, setSizeFilters] = useState<any>([]);
  const [filterdProducts, setFilterdProducts] = useState<any>([]);

  useEffect(() => {
    getProducts();
    getPatternFilters();
    getColorFilters();
    getSizeFilters();
  }, []);

  useEffect(() => {
    getProducts();
  }, [url]);

  const getProducts = async () => {
    window.scrollTo(0, 0);
    await getDocs(collection(db, "products")).then((querySnapshot) => {
      const newData: any = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (lastUrl) {
        let url2 = lastUrl?.split("=")[1];
        if (lastUrl?.split("=")[0] == "category") {
          console.log(lastUrl?.split("=")[0]);

          let data = newData.filter(
            (data: any) => data?.category?.id === url2?.split("&")[0]
          );

          if (url2?.split("&")[1] == "pattern") {
            let patternVal = lastUrl?.split("=")[2];

            if (patternVal?.includes("+")) {
              patternVal = patternVal?.replace(/\+/g, " ");
            }

            if (patternVal?.includes("%")) {
              patternVal = patternVal?.replace("%28", "(");
              patternVal = patternVal?.replace("%29", ")");
            }

            let tempData: any = [];
            data?.map((value: any, index: number) => {
              value?.pattern?.map((patVal: any, patInd: number) => {
                if (patVal?.value == patternVal) {
                  tempData.push(value);
                }
              });
            });
            setProducts(tempData);
            setFilterdProducts(tempData);
          } else {
            setProducts(data);
            setFilterdProducts(data);
          }
        }

        if (lastUrl?.split("=")[0] == "pattern") {
          console.log(lastUrl?.split("=")[0]);

          let data = newData;

          let patternVal = lastUrl?.split("=")[1];

          if (patternVal?.includes("+")) {
            patternVal = patternVal?.replace(/\+/g, " ");
          }

          if (patternVal?.includes("%")) {
            patternVal = patternVal?.replace("%28", "(");
            patternVal = patternVal?.replace("%29", ")");
          }

          let tempData: any = [];
          data?.map((value: any, index: number) => {
            value?.pattern?.map((patVal: any, patInd: number) => {
              if (patVal?.value == patternVal) {
                tempData.push(value);
              }
            });
          });
          setProducts(tempData);
          setFilterdProducts(tempData);
        }
      } else {
        setProducts(newData);
        setFilterdProducts(newData);
      }
    });
  };

  const getSizeFilters = async () => {
    await getDocs(collection(db, "sizeFilters")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setSizeFilters(newData);
    });
  };

  const getPatternFilters = async () => {
    await getDocs(collection(db, "patternFilters")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setPatternFilters(newData);
    });
  };

  const getColorFilters = async () => {
    await getDocs(collection(db, "colorFilters")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setColorFilters(newData);
    });
  };

  const [filterPatternsArray, setFilterPatternsArray] = useState<any>([]);
  const [filterColorArray, setFilterColorArray] = useState<any>([]);
  const [filterSizeArray, setFilterSizeArray] = useState<any>([]);

  useEffect(() => {
    let tempData: any = [];
    filterPatternsArray?.map((val: any, ind: number) => {
      products?.map((value: any, index: number) => {
        value?.pattern?.map((patVal: any, patInd: number) => {
          if (patVal?.value === val) {
            tempData.push(value);
          }
        });
      });
    });

    setFilterdProducts(filterPatternsArray?.length > 0 ? tempData : products);
  }, [filterPatternsArray]);

  useEffect(() => {
    let tempData: any = [];
    filterColorArray?.map((val: any, ind: number) => {
      filterdProducts?.map((value: any, index: number) => {
        value?.colours?.map((patVal: any, patInd: number) => {
          if (patVal?.value === val) {
            tempData.push(value);
          }
        });
      });
    });

    setFilterdProducts(filterColorArray?.length > 0 ? tempData : products);
  }, [filterColorArray]);

  useEffect(() => {
    let tempData: any = [];
    filterSizeArray?.map((val: any, ind: number) => {
      filterdProducts?.map((value: any, index: number) => {
        value?.sizes?.map((patVal: any, patInd: number) => {
          if (patVal?.value === val) {
            tempData.push(value);
          }
        });
      });
    });

    setFilterdProducts(filterColorArray?.length > 0 ? tempData : products);
  }, [filterSizeArray]);

  const filterData = (e: any, type: string, value: any) => {
    console.log(filterdProducts);
    switch (type) {
      case "pattern":
        if (e?.target?.checked) {
          setFilterPatternsArray([...filterPatternsArray, value]);
        } else {
          setFilterPatternsArray((filterPatternsArray: any) => {
            return filterPatternsArray.filter((data: any) => data !== value);
          });
        }
        break;
      case "color":
        console.log(value);
        if (e?.target?.checked) {
          setFilterColorArray([...filterColorArray, value]);
        } else {
          setFilterColorArray((filterColorArray: any) => {
            return filterColorArray.filter((data: any) => data !== value);
          });
        }
        break;
      case "size":
        if (e?.target?.checked) {
          setFilterSizeArray([...filterSizeArray, value]);
        } else {
          setFilterSizeArray((filterSizeArray: any) => {
            return filterSizeArray.filter((data: any) => data !== value);
          });
        }
        break;
      default:
        break;
    }
  };
  console.log(filterdProducts);
  return (
    <>
      <Col className="displayManul mb-5 px-3 allProducts">
        <Col xs={12} md={3} className="d-flex flex-wrap px-2 mt-5">
          <div className="filter w-100">
            <div className="d-flex w-100 align-items-center justify-content-between px-3 py-4 borderBottom">
              <Col>
                <h6>Filters</h6>
              </Col>
              <Col>
                <p className="text-end">{products?.length} results</p>
              </Col>
            </div>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header><h6>Product type</h6></Accordion.Header>
                <Accordion.Body>
                {patternFilters?.map((val: any, ind: number) => (
                  <Form.Check
                    type="checkbox"
                    id={`pattern-${ind}`}
                    label={val?.name}
                    onChange={(e: any) => filterData(e, "pattern", val?.name)}
                  />
                ))}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header><h6>Color</h6></Accordion.Header>
                <Accordion.Body>
                {colorFilters?.map((val: any, ind: number) => (
                  <Form.Check
                    type="checkbox"
                    id={`pattern-${ind}`}
                    label={val?.name}
                    onChange={(e: any) =>
                      filterData(
                        e,
                        "color",
                        val?.code != "" ? val?.code : val?.image
                      )
                    }
                  />
                ))}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header><h6>Size</h6></Accordion.Header>
                <Accordion.Body>
                 {sizeFilters?.map((val: any, ind: number) => (
                  <Form.Check
                    type="checkbox"
                    id={`pattern-${ind}`}
                    label={val?.name}
                    onChange={(e: any) => filterData(e, "size", val?.name)}
                  />
                ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          </div>
        </Col>
        <Col xs={12} md={9} className="d-flex flex-wrap">
          {filterdProducts?.map((val: any, ind: number) => (
            <Col
              xs={6}
              md={3}
              className="px-2 mt-5 productDiv"
              onClick={() =>
                navigate({
                  pathname: "/product",
                  search: createSearchParams({
                    pi: val?.id,
                  }).toString(),
                })
              }
            >
              <div className="FreshProductDivImg w-100">
                <img src={val?.image[0]} className="h-100" />
              </div>
              <p className="w-100 text-center my-2">{val?.name}</p>

              <a
                href="https://api.whatsapp.com/send?phone=918104380250"
                target="_blank"
              >
                <Button variant="success" className="w-100">
                  Contact Us <FaWhatsapp />
                </Button>
              </a>

              <div className="d-flex w-100 align-items-center justify-content-center mt-3">
                {val?.sizes &&
                  val?.sizes.map((value: any, index: number) => (
                    <span key={index} className="mx-1 freshSizeSpn px-1">
                      {value?.label}
                    </span>
                  ))}
              </div>
            </Col>
          ))}
        </Col>
      </Col>
    </>
  );
}

export default Products;

import React from "react";
import "./Newsletter.css";
import { Button, Form, InputGroup } from "react-bootstrap";

function Newsletter() {
  return (
    <>
      <div className="NewsletterContainer my-5 p-5">
        <h5 className="w-100 text-center">Newsletter</h5>
        <p className="mt-4 mb-5">
          Subscribe to get special offers, free giveaways, and
          once-in-a-lifetime deals. We promise to send emails you will love.
        </p>

        <InputGroup className="emailJoinDiv">
          <Form.Control
            placeholder="join@gmail.com"
            aria-label="join@gmail.com"
            aria-describedby="basic-addon2"
          />
          <InputGroup.Text id="basic-addon2">
            <Button variant="dark">Join</Button>
          </InputGroup.Text>
        </InputGroup>
      </div>
    </>
  );
}

export default Newsletter;

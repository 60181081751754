import React, { useEffect, useState } from "react";
import "./FreshProducts.css";
import { Button } from "react-bootstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaWhatsapp } from "react-icons/fa";
import { createSearchParams, useNavigate } from "react-router-dom";

function FreshProducts() {
  const navigate = useNavigate();
  const [products, setProducts] = useState<any>([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    await getDocs(collection(db, "products")).then((querySnapshot) => {
      const newData: any = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
 
      let filterData = newData.filter((data:any) => data?.shopFresh === "true");
      setProducts(filterData);

    });
  };

  const responsive2 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
 
  return (
    <>
      <div className="spacingContainer">
        <div className="FreshProductsContainer">
          <h4 className="col-12">Fresh & New Products</h4>

          {/* <div className="FreshProductsList d-flex w-100"> */}
          <Carousel
            responsive={responsive2}
            autoPlaySpeed={1000}
            autoPlay={false}
            infinite={true}
          >
            {products?.map((val: any, ind: number) => (
              <>
                <div key={ind} className="FreshProductDiv mx-2"
                onClick={() =>
                  navigate({
                    pathname: "/product",
                    search: createSearchParams({
                      pi: val?.id,
                    }).toString(),
                  })
                }
                >
                  <div className="FreshProductDivImg">
                    <img src={val?.image[0]} />
                  </div>
                  <p className="w-100 text-center mb-2 mt-3 freshProductName">{val?.name}</p>

                  <a href="https://api.whatsapp.com/send?phone=918104380250" target='_blank'>
                  <Button variant="success" className="w-100">
                    Contact Us <FaWhatsapp />
                    </Button>
                    </a>

                  <div className="d-flex w-100 align-items-center justify-content-center mt-3">
                    {val?.sizes.map((value: any, index: number) => (
                      <span key={ind} className="mx-1 freshSizeSpn px-1">
                        {value?.label}
                      </span>
                    ))}
                  </div>
                </div>
              </>
            ))}
          </Carousel>
          {/* </div> */}

          <div className="d-flex w-100 align-items-center justify-content-center mt-5 pt-3">
            <Button className="px-4" variant="dark">
              View All
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FreshProducts;

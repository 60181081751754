import React, { useEffect, useState } from "react";
import "./Categories.css";
import banner2 from "../../Assets/banner2.webp";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { createSearchParams, useNavigate } from "react-router-dom";

function Categories() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<any>([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    await getDocs(collection(db, "categories")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log(newData)
      setCategories(newData);
    });
  };

  return (
    <>
      <div className="spacingContainer">
        <div className="catrgoriesContainer">
          <h4 className="col-12">Categories</h4>
          <div className="CategoriesList mt-3">
            {categories.map((val: any, ind: any) => (
              <div
                key={ind}
                className={`CategoryDiv pt-1 ${
                  ind === 0 ? "ml-0 mr-3" : "mx-3"
                }`}
                onClick={() =>
                  navigate({
                    pathname: "/products",
                    search: createSearchParams({
                      category: val?.id,
                      name: val?.name
                    }).toString(),
                  })
                }
              >
                <div className="CategoryImg">
                  <img src={val?.image} />
                </div>
                <p className="w-100 text-center mt-3 categoryName">
                  {val?.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Categories;

import React from "react";
 import "./App.css";
import Layout from "./Layouts/Layout";
    
function App() {
  return (
     <Layout />
  );
}

export default App;

import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { db } from "../../firebase";
import { LiaWhatsapp } from "react-icons/lia";

function ProductDetails() {
  let url = window.location.href;
  const productId = url.split("=")[1];

  const [productData, setProductData] = useState<any>();

  const [productImages, setProductImages] = useState<any>([]);

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = async () => {
    window.scrollTo(0, 0);

    let newData: any;
    const docRef = doc(db, "products", productId);
    try {
      const docSnap = await getDoc(docRef);
      newData = docSnap.data();
    } catch (error) {
      console.log(error);
    }
    setProductData(newData);

    console.log(newData);
    let images: any = [];
    newData?.image?.map((val: any, ind: number) => {
      images.push({
        original: val,
        thumbnail: val,
      });
    });

    setProductImages(images);
  };

  return (
    <>
      <div className="spacingContainer my-5 productDetailsDiv">
        <Col className="displayManul px-md-4">
          <Col className="px-md-3 col-md-6">
            <ImageGallery items={productImages} />
          </Col>

          <Col className="px-md-3 mt-md-4 col-md-6">
            <h1 className="mb-2">{productData?.name}</h1>

            <div className=" w-100 my-3">
              <p className="w-100">Size Available</p>
              <div className="w-100 d-flex pt-2">
                {productData?.sizes?.length > 0 && productData?.sizes.map((val: any, ind: number) => (
                  <span key={ind} className="mx-1 freshSizeSpn px-1">
                    {val?.label}
                  </span>
                ))}
              </div>
            </div>

            <div className=" w-100 my-4">
              <p className="w-100">Colours Available</p>
              <div className="w-100 d-flex pt-4">
                {productData?.colours.map((val: any, ind: number) => (
                  <div key={ind} className="mx-2 px-0">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                      {val?.value.includes('#') ? <div
                        className="colorDemoDiv"
                        style={{ background: val?.value }}
                      ></div> : <div className="colorDemoDiv">
                        <img src={val?.value} />
                        </div>}
                    </div>
                    <p className="w-100 text-center colorNameP mt-1">
                      {val?.label}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <p className="my-4">{productData?.style}</p>

            <p className="pdHeading mb-2">PRODUCT DESCRIPTION</p>

            <div className="d-flex">
              <h6>FIT TYPE :</h6>
              <p className="mx-2">{productData?.fit}</p>
            </div>

            <div className="d-flex">
              <h6>PATTERN :</h6>
              {productData?.pattern?.map((val: any, ind: number) => (
                <p className="mx-2">{val?.label}</p>
              ))}
            </div>

            <div className="d-flex">
              <h6>MATERIAL :</h6>
              <p className="mx-2">{productData?.material}</p>
            </div>

            <div className="d-flex">
              <h6>EMBROIDERY :</h6>
              <p className="mx-2">{productData?.embroidery}</p>
            </div>

            <div className="d-flex">
              <h6>WASHED :</h6>
              <p className="mx-2">{productData?.washed}</p>
            </div>

            <div className="d-flex">
              <h6>STRETCH :</h6>
              <p className="mx-2">{productData?.stretch}</p>
            </div>

            <a href="https://api.whatsapp.com/send?phone=918104380250" target='_blank'>
      <div className="whatsappOption mt-5">
        Chat On WhatsApp for Purchase <LiaWhatsapp />
      </div>
      </a>

           </Col>
        </Col>
      </div>
    </>
  );
}

export default ProductDetails;

import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

function VisitUs() {
  return (
    <>
      <div className="spacingContainer">
        <div className="contactMainDiv my-5">
          <h1 className="w-100 text-center">Contact Us</h1>

          <div>
            <Form.Label className="mt-3" htmlFor="name">
              Name
            </Form.Label>
            <Form.Control
              type="text"
              id="name"
              aria-describedby="passwordHelpBlock"
            />

            <Form.Label className="mt-3" htmlFor="phone">
              Phone
            </Form.Label>
            <Form.Control
              type="number"
              id="phone"
              aria-describedby="passwordHelpBlock"
            />

            <Form.Label className="mt-3" htmlFor="email">
              Email
            </Form.Label>
            <Form.Control
              type="email"
              id="email"
              aria-describedby="passwordHelpBlock"
            />

            <Form.Label className="mt-3" htmlFor="city">
              City
            </Form.Label>
            <Form.Control
              type="text"
              id="city"
              aria-describedby="passwordHelpBlock"
            />

            <Form.Label className="mt-3">Message</Form.Label>
            <Form.Control as="textarea" rows={3} />

            <Button variant="dark" className="w-100 mt-3 py-2">
              Dark
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default VisitUs;

import React from "react";
import MainSlider from "../../Components/MainSlider/MainSlider";
import Categories from "../../Components/Categories/Categories";
import FreshProducts from "../../Components/FreshProducts/FreshProducts";
import ShopByPattern from "../../Components/ShopByPattern/ShopByPattern";
import Ratings from "../../Components/Ratings/Ratings";
import Newsletter from "../../Components/Newsletter/Newsletter";

function Home() {
  return (
    <>
      <MainSlider />
      <Categories />
      <FreshProducts />
      <ShopByPattern />
      <Ratings />
      <Newsletter />
    </>
  );
}

export default Home;

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage} from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
   apiKey: "AIzaSyBTFBtpnLrexOAzQk-cL3h8y4LwyiY646U",
  authDomain: "prokills.firebaseapp.com",
  projectId: "prokills",
  storageBucket: "prokills.appspot.com",
  messagingSenderId: "619109264857",
  appId: "1:619109264857:web:6f8c29611bf3e7ebcd3dc8",
 };
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
export const fireStorage = getStorage(app);
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Rating } from "react-simple-star-rating";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "./Ratings.css";

function Ratings() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [testimonials, setTestimonials] = useState<any>([]);

  useEffect(() => {
    getTestimonials();
  }, []);

  const getTestimonials = async () => {
    await getDocs(collection(db, "ratereview")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setTestimonials(newData);
      console.log(newData);
    });
  };

  return (
    <>
      <div className="spacingContainer mt-5 pt-5">
        <div className="my-5 ratingMainDiv">
          <div className="w-100">
            <h1 className="w-100 text-center">Let customers speak for us</h1>
            <p className="w-100 text-center headingP mb-5">
              from {testimonials?.length} reviews
            </p>
          </div>
          <Carousel
            responsive={responsive}
            autoPlaySpeed={2000}
            autoPlay={true}
            infinite={true}
          >
            {testimonials?.map((val: any, ind: number) => (
              <div>
                <div className="d-flex justify-content-center">
                  <div className="testimonialImg">
                    <img src={val?.image} />
                  </div>
                  <div className="reviewInfo mx-3">
                    <Rating initialValue={val?.rate} />
                    <h5>{val?.title}</h5>
                    <p className="review">{val?.review}</p>

                    <h6 className="mb-0">{val?.name}</h6>
                    <p className="productName">{val?.product?.name}</p>
                    <p className="date">{val?.date}</p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default Ratings;

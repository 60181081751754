import React, { useEffect, useState } from "react";
import { Button, Carousel } from "react-bootstrap";
import banner1 from "../../Assets/banner1.webp";
import banner2 from "../../Assets/banner2.webp";
import "./MainSlider.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

function MainSlider() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const [topBanners, setTopBanners] = useState<any>([]);

  useEffect(() => {
    getTopBanners();
  }, []);

  const getTopBanners = async () => {
    await getDocs(collection(db, "topBanners")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setTopBanners(newData);
      console.log(newData);
    });
  };

  const handleSelect = (selectedIndex: any) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="spacingContainer">
      <div className="mainSlider">
        <Carousel activeIndex={index} onSelect={handleSelect}>
          {topBanners?.map((val: any, ind: number) => (
            <Carousel.Item>
              <div className="bannerImagContainer">
                <img src={val?.image} />
              </div>
              <Carousel.Caption>
                <Button variant="light" onClick={() => navigate("/products")}>View Products</Button>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default MainSlider;
